import { Box, Icon } from "@mui/material";
import React from "react";
import { Mensagem } from "./types";

type VisualizacaoMensagemProps = {
  mensagem?: Mensagem | undefined;
};

const defaultProps: VisualizacaoMensagemProps = {
  mensagem: undefined,
};

const VisualizacaoMensagem: React.FC<VisualizacaoMensagemProps> = ({
  mensagem,
}) => {
  return (
    <Box className="box-msg">
      <div className="header-msg">
        <Icon className="icone-msg">{mensagem?.icone}</Icon>
        <div className="titulo-msg">{mensagem?.titulo}</div>
      </div>
      <div className="texto-msg">{mensagem?.texto}</div>
      <div className="data-msg">{mensagem?.dataCriacaoFormatada}</div>
    </Box>
  );
};

VisualizacaoMensagem.defaultProps = defaultProps;

export default VisualizacaoMensagem;
