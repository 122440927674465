import React, { FC, useMemo } from "react";
import { LoginRequiredContainer } from "../../containers/LoginRequiredContainer";
import { NotFoundContainer } from "../../containers/NotFoundContainer";
import { AppResponse } from "../../shared-types";
import { AppBundleLaucher } from "./AppBundleLaucher";
import { AppIframeLauncher } from "./AppIframeLaucher";
import { RequestStatus } from "../../util/Service";
import { LoadingButton } from "@mui/lab";
import { GenericErrorContainer } from "../../containers/GenericErrorContainer";

export interface AppLoaderProps {
  app?: AppResponse | null;
  loading: boolean;
  authenticated: boolean;
  ssr?: boolean;
  requestStatus?: RequestStatus;
}
export const AppLoader: FC<AppLoaderProps> = ({
  app,
  loading,
  authenticated,
  ssr,
  requestStatus,
}) => {
  const isUnauthorized = useMemo(() => {
    return requestStatus === RequestStatus.UNAUTHORIZED;
  }, [requestStatus]);

  if (loading) {
    return <LoadingButton variant={"text"} />;
  }

  if (requestStatus === RequestStatus.INTERNAL_ERROR) {
    return <GenericErrorContainer />;
  }

  if (isUnauthorized && authenticated) {
    console.log("redirect by unauthorized");
    window.location.replace(
      `${process.env.PUBLIC_URL}/app/unauthorized-access`
    );
    return null;
  }

  if (requestStatus === RequestStatus.NOT_FOUND) {
    return <NotFoundContainer />;
  }

  if (isUnauthorized && !authenticated) {
    return <LoginRequiredContainer />;
  }

  if (!app || (!app && ssr)) {
    return null;
  }

  if (app.integrationType === "iframe") {
    return <AppIframeLauncher app={app} />;
  }

  if (app.integrationType === "bundle") {
    return <AppBundleLaucher app={app} />;
  }

  return null;
};
