import React, { FC } from "react";
import { FooterContainer } from "../modules/footer/footer.container";
import { DepartmentContainer } from "../modules/department/department.container";
import { HeaderGenericContainer } from "../modules/header-generic/header-generic.container";
import { SearchBarContainer } from "../components/SearchBar/SearchBar.container";
import { LoginAreaContainer } from "../modules/login-area/login-area.container";

export const Department: FC = () => {
  return (
    <div>
      <HeaderGenericContainer
        centerElement={<SearchBarContainer />}
        rightElement={<LoginAreaContainer variant="blue" />}
      />
      <DepartmentContainer />
      <FooterContainer />
    </div>
  );
};
