import React, { useMemo } from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import { Routes } from "./routes";
import { ChatWelcomeMessage } from "./components/chat-welcome-message/chat-welcome-message";

function App() {
  const routes = useMemo(
    () =>
      Object.keys(Routes).map((path) => {
        const RouteComponent = Routes[path];
        return (
          <Route key={path} path={path}>
            <RouteComponent />
          </Route>
        );
      }),
    []
  );
  return (
    <div>
      <Switch>{routes}</Switch>
    </div>
  );
}

export default App;
