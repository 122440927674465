import React from "react";
import { Container } from "@mui/material";
import { TopBar } from "./TopBar";
import "./header.css";

export interface HeaderProps {
  rightElement?: React.ReactElement;
  centerElement?: React.ReactElement;
  openSideBarIcon: React.ReactElement;
}

export const Header = (props: HeaderProps) => {
  const publicUrl = process.env.PUBLIC_URL || "";

  return (
    <header className="xvia-header-area">
      <TopBar openSideBarIcon={props.openSideBarIcon} />
      <div className="xvia-header-content-generic">
        <Container className="xvia-content-section xvia-header-content-section">
          <div className="xvia-header-logo">
            <a href="/">
              <picture>
                <source
                  srcSet={`${publicUrl}/assets/img/logo/logo-mobile.svg`}
                  media="(max-width: 991px)"
                />
                <img
                  src={`${publicUrl}/assets/img/logo/logo.svg`}
                  alt="Governo do Estado do Piauí"
                />
              </picture>
            </a>
          </div>
          <div className="xvia-header-center">{props.centerElement}</div>
          <div className="xvia-header-elements">{props.rightElement}</div>
        </Container>
      </div>
    </header>
  );
};
