import { Box, Stack, Typography } from "@mui/material";
import { FC } from "react";

import { CNH, Paycheck, UserVehicle } from "../../apis/user-related-services";
import { Container } from "../../components/container/container";
import CnhCard from "./cnh-card";
import PaycheckCard from "./paycheck-card";
import VehicleCard from "./vehicle-card";

import "./user-related-widgets.css";

export interface UserRelatedWidgetsProps {
  paychecks: Paycheck[];
  cnh: CNH[];
  vehicles: UserVehicle[];
}

export const UserRelatedWidgets: FC<UserRelatedWidgetsProps> = ({
  cnh,
  paychecks,
  vehicles,
}) => {
  return (
    <div className="xvia-user-related-widgets">
      <Container className="xvia-user-related-widgets__container">
        <Stack
          gap={5}
          direction="row"
          sx={(theme) => ({
            width: "100%",
            mb: 2,
            [theme.breakpoints.down("md")]: { flexWrap: "wrap" },
            [theme.breakpoints.down("sm")]: { flexDirection: "column" },
          })}
        >
          {paychecks && paychecks.length > 0 && (
            <Box
              sx={(theme) => ({
                width: "100%",
                [theme.breakpoints.up(820)]: {
                  maxWidth: "358px",
                },
                [theme.breakpoints.down("sm")]: { width: "100%", mb: 2, mr: 0 },
              })}
            >
              <Typography
                variant="h6"
                sx={(theme) => ({
                  mb: 2,
                  fontWeight: "700",
                  [theme.breakpoints.down("sm")]: { mb: 1 },
                })}
              >
                Meu Contracheque
              </Typography>
              <PaycheckCard data={paychecks} />
            </Box>
          )}
          {cnh && cnh.length > 0 && (
            <Box
              sx={(theme) => ({
                width: "100%",
                [theme.breakpoints.up(820)]: {
                  maxWidth: "358px",
                },
                [theme.breakpoints.down("sm")]: { width: "100%", mb: 2 },
              })}
            >
              <Typography
                variant="h6"
                sx={(theme) => ({
                  mb: 2,
                  fontWeight: "700",
                  [theme.breakpoints.down("sm")]: { mb: 1 },
                })}
              >
                Minha CNH
              </Typography>
              {!!cnh && (
                <CnhCard
                  data={{
                    ...cnh[0],
                  }}
                />
              )}
            </Box>
          )}
          {vehicles && vehicles.length > 0 && (
            <Box
              sx={(theme) => ({
                width: "100%",
                overflow: "hidden",
                [theme.breakpoints.down("sm")]: { width: "100%", ml: 0 },
              })}
            >
              <Typography
                variant="h6"
                sx={{
                  width: "100%",
                  marginBottom: "1rem",
                  fontWeight: "700",
                }}
              >
                Meus Veículos
              </Typography>
              <div
                style={{
                  overflowX: "auto",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    gap: "0.5rem",
                  }}
                >
                  {vehicles?.map((vehicle, i) => {
                    return (
                      <div>
                        <VehicleCard key={i} data={vehicle} />
                      </div>
                    );
                  })}
                </Stack>
              </div>
            </Box>
          )}
        </Stack>
      </Container>
    </div>
  );
};
