import {
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LoginArea, LoginAreaProps, LoginAreaVariant } from "./login-area";
import { useMediaQuery } from "@mui/material";
import { useKeycloak } from "@react-keycloak/ssr";
import { KeycloakInstance } from "keycloak-js";

type LoginAreaContainerProps = {
  variant?: LoginAreaVariant;
};

export const LoginAreaContainer = ({
  variant = "white",
}: LoginAreaContainerProps) => {
  const { keycloak } = useKeycloak<KeycloakInstance>();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState<Record<string, any> | null>(null);
  const isMobile = useMediaQuery("(max-width: 991px)");

  const onClickDocuments = useCallback(() => {
    document.location.href = "/app/app-documentos";
  }, []);

  const onClickSolicitations = useCallback(() => {
    document.location.href = "/app/acompanhar-minhas-solicitacoes";
  }, []);

  const onClickLogin = useCallback(() => {
    keycloak?.login({
      redirectUri: `${process.env.BACKEND_ENDPOINT}`,
    });
  }, [keycloak]);

  const onClickLogout = useCallback(() => {
    keycloak?.logout({});
  }, [keycloak]);

  const onClickAccount = useCallback(() => {
    keycloak?.accountManagement();
  }, [keycloak]);

  const userName = useMemo(() => {
    if (!userInfo?.name || !keycloak?.token) {
      return;
    }
    const parts = (userInfo.name as string).split(" ");
    if (parts.length > 1) {
      return `${parts[0]} ${parts[parts.length - 1]}`;
    }

    return userInfo.name;
  }, [userInfo?.name, keycloak?.token]);

  const userLevelRole = useMemo(() => {
    if (!userInfo?.name || !keycloak?.token) {
      return;
    }

    if (userInfo?.roles.some((role: string) => role === "opala"))
      return "opala";
    if (userInfo?.roles.some((role: string) => role === "ouro")) return "ouro";
    if (userInfo?.roles.some((role: string) => role === "prata"))
      return "prata";
    if (userInfo?.roles.some((role: string) => role === "bronze"))
      return "bronze";
  }, [userInfo, keycloak?.token]);

  useEffect(() => {
    if (!!keycloak?.token) {
      setLoggedIn(true);
      return;
    }
    setLoggedIn(false);
  }, [keycloak?.token, userInfo]);

  useEffect(() => {
    if (keycloak?.token)
      keycloak
        ?.loadUserInfo()
        .then((profile) => {
          setUserInfo(profile);
        })
        .catch((e) => {
          // do nothing
        });
  }, [keycloak?.token]);

  const props: LoginAreaProps = {
    isLoggedIn,
    userName,
    onClickLogin,
    onClickAccount,
    onClickLogout,
    userLevelRole,
    onClickDocuments,
    onClickSolicitations,
    variant,
    isMobile,
  };

  return createElement(LoginArea, props);
};
