import React, { useEffect } from "react";

import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { AppLoaderContainer } from "./containers/AppLoaderContainer";
import { NotFoundContainer } from "./containers/NotFoundContainer";
import { LoginContainer } from "./containers/LoginContainer";
import ReactGA from "react-ga4";
import { usePageTrackingGA } from "./hooks";
import { AppMessageListener } from "./containers/AppMessageListener";
import { ChatWelcomeMessage } from "./components/chat-welcome-message/chat-welcome-message";

const App = () => {
  // GA Tracking
  const location = useLocation();
  const history = useHistory();

  usePageTrackingGA(location);

  useEffect(() => {
    const googleAnalyticsId =
      typeof window !== "undefined" ? window?.GOOGLE_ANALYITCS_ID : undefined;
    if (!googleAnalyticsId) return;
    ReactGA.initialize(googleAnalyticsId);
  }, []);

  useEffect(() => {
    return history.listen((location, action) => {
      const routeChangedEvent = new CustomEvent("portalRouteChanged", {
        detail: {
          pathname: location.pathname,
        },
      });

      dispatchEvent(routeChangedEvent);
    });
  }, [history]);

  return (
    <LoginContainer>
      <AppMessageListener />
      <Switch>
        <Route path={"/app/:id"}>
          <AppLoaderContainer />
        </Route>
        <Route exact={true} path="/">
          <AppLoaderContainer slug={"catalog"} />
        </Route>
        <Route path="*">
          <NotFoundContainer />
        </Route>
      </Switch>
      <ChatWelcomeMessage />
    </LoginContainer>
  );
};

export default App;
