import { useCallback, useEffect, useState } from "react";

export type Error = {
  type: "validation" | "network";
  response?: any;
};
const useRequest = <T>(
  callback: (signal: AbortSignal) => Promise<T>,
  defaultValue: T
) => {
  const [data, setData] = useState<T>(defaultValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const refresh = useCallback(() => {
    setLoading(true);
    setError(undefined);
    const controller = new AbortController();
    callback(controller.signal)
      .then((data) => {
        setData(data);
        setError(undefined);
      })
      .catch((reason) => {
        if (reason.message === "canceled") return;
        setData(defaultValue);
        if (reason.response) {
          const { data } = reason.response;
          setError({ type: "validation", response: data });
          return;
        } else {
          setError({ type: "network" });
          return;
        }
      })
      .finally(() => setLoading(false));
    return () => controller.abort();
  }, [callback]);

  useEffect(refresh, [callback]);

  return { data, loading, error, refresh };
};

export default useRequest;
