import React, { useCallback } from "react";
import "./login-area.css";
import {
  ListItemText,
  Menu,
  MenuItem,
  Button as MUIButton,
  Box,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import Notificacoes from "../../components/notificacoes/Notificacoes";

const UserIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
    <circle cx="12" cy="7" r="4" />
  </svg>
);

const ChevronDownIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="m6 9 6 6 6-6" />
  </svg>
);

const UserBadgeIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.7385 6.44495L8.5 10.9999L6 9.49995L3.5 10.9999L4.2615 6.44495M9 4C9 5.65685 7.65685 7 6 7C4.34315 7 3 5.65685 3 4C3 2.34315 4.34315 1 6 1C7.65685 1 9 2.34315 9 4Z"
      stroke="#E6E6E6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="4" x2="20" y1="12" y2="12" />
    <line x1="4" x2="20" y1="6" y2="6" />
    <line x1="4" x2="20" y1="18" y2="18" />
  </svg>
);

export type LoginAreaVariant = "white" | "blue";

export interface LoginAreaProps {
  isLoggedIn: boolean;
  userName?: string;
  onClickDocuments: () => void;
  onClickSolicitations: () => void;
  onClickLogin: () => void;
  onClickAccount: () => void;
  onClickLogout: () => void;
  userLevelRole?: string;
  variant?: LoginAreaVariant;
  isMobile?: boolean;
}

export const LoginArea = ({
  isLoggedIn,
  onClickLogin,
  onClickAccount,
  onClickLogout,
  onClickDocuments,
  onClickSolicitations,
  userName,
  userLevelRole,
  variant = "white",
  isMobile,
}: LoginAreaProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClickLogin = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!isLoggedIn) {
        onClickLogin();
        return;
      }
      setAnchorEl(event.currentTarget);
    },
    [isLoggedIn, setAnchorEl, onClickLogin]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const userLevelColorMap: Record<string, string> = {
    opala: "#167974",
    ouro: "#DDA646",
    prata: "#8A8080",
    bronze: "#A0701D",
  };

  const variantColorMap: Record<
    LoginAreaVariant,
    { bg: string; hover: string; text: string }
  > = {
    blue: {
      bg: "#034EA2",
      hover: "#023269",
      text: "#E6E6E6",
    },
    white: {
      bg: "#EFEFEF",
      hover: "#419AFF",
      text: "#093089",
    },
  };

  return (
    <div className="xvia-login-area">
      {isLoggedIn && <Notificacoes isBlue={variant === "blue"} />}
      <MUIButton
        disableRipple
        variant="contained"
        sx={{
          backgroundColor: variantColorMap[variant].bg,
          padding: isLoggedIn
            ? isMobile
              ? "12px"
              : "12px 12px 12px 24px"
            : "12px",
          gap: "8px",
          borderRadius: "14px",
          color: variantColorMap[variant].text,
          fontSize: "14px",
          fontWeight: 600,
          lineHeight: "14px",
          textAlign: "center",
          boxShadow: "none",

          "&:hover": {
            backgroundColor: variantColorMap[variant].hover,
            boxShadow: "none",
          },
        }}
        onClick={handleClickLogin}
      >
        {isLoggedIn ? (
          isMobile ? (
            <MenuIcon />
          ) : (
            <>
              {userName as string} <ChevronDownIcon />
            </>
          )
        ) : isMobile ? (
          <UserIcon />
        ) : (
          <>
            <UserIcon />
            ACESSO CIDADÃO
          </>
        )}
      </MUIButton>
      <Menu
        id="login-menu"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={(theme) => ({
          "& .MuiPaper-root": {
            borderRadius: "8px",
            marginTop: theme.spacing(2),
            minWidth: "190px",
            color: "#262626",
            backgroundColor: "#F9FCFF",
            boxShadow: "none",

            "& .MuiMenu-list": {
              padding: "0px",
            },

            "& .MuiMenuItem-root": {
              minHeight: "35px",
              padding: "8px 16px",
              "& .MuiListItemText-root": {
                "& span": {
                  fontSize: "14px",
                  fontWeight: 600,
                  lineHeight: "14px",
                  textAlign: "left",
                },
              },

              "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: isMobile ? 0 : theme.spacing(1.5),
              },
            },
          },
        })}
      >
        {userLevelRole && (
          <MenuItem
            disabled
            sx={{
              backgroundColor: alpha(userLevelColorMap[userLevelRole], 0.5),
              "&.Mui-disabled": { opacity: 1 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                color: "#E6E6E6",
                padding: "6px 8px",
                borderRadius: "8px",
                backgroundColor: userLevelColorMap[userLevelRole],
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "12px",
                textAlign: "center",
                textTransform: "capitalize",
              }}
            >
              <UserBadgeIcon />
              {userLevelRole}
            </Box>
          </MenuItem>
        )}

        <MenuItem disableRipple onClick={onClickAccount}>
          <ListItemText>Meu perfil</ListItemText>
        </MenuItem>
        <MenuItem disableRipple onClick={onClickDocuments}>
          <ListItemText>Documentos</ListItemText>
        </MenuItem>
        <MenuItem disableRipple onClick={onClickSolicitations}>
          <ListItemText>Solicitações</ListItemText>
        </MenuItem>
        <MenuItem
          disableRipple
          sx={{ "&:hover": { backgroundColor: alpha("#FDE2DE", 0.5) } }}
          onClick={onClickLogout}
        >
          <ListItemText sx={{ color: "#8C1D0A" }}>Sair</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};
