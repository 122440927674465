import React, { FC, useCallback, useEffect, useState } from "react";
import {
  RecommendedServices,
  RecommendedServicesProps,
} from "./recommended-services";
import { useWebgate } from "@xvia/webgate-connect";
import { Catalog } from "../../apis/search-catalog";
import { getRecommendations } from "../../apis/recommendations-catalog";
import { getServerData } from "../../util/get-server-data";
import { useHistory } from "react-router-dom";

export const RecommendedServicesContainer: FC = () => {
  const { accessToken, serverData, portalConnect } = useWebgate();
  const [recommendedCatalogList, setRecommendedCatalogList] = useState<
    Catalog[]
  >(serverData?.recommendation || getServerData("recommendation", null));
  let history = useHistory();

  const getServerRecommended = useCallback(() => {
    getRecommendations(accessToken)
      .then((result) => {
        setRecommendedCatalogList(result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [accessToken]);
  const onClickCard = useCallback(
    (link: string) => {
      if (link.startsWith("http")) {
        window.location.href = link;
      } else {
        const path = link.startsWith("/app/") ? link.substring(4) : link;
        history.push(path);
      }
    },
    [history]
  );

  useEffect(() => {
    if (!recommendedCatalogList?.length && !!accessToken)
      getServerRecommended();
  }, [accessToken, getServerRecommended, recommendedCatalogList?.length]);

  const clickMore = useCallback(() => {
    portalConnect?.redirectTo("/app/dashboard");
  }, [portalConnect]);

  const recommendedServicesProps: RecommendedServicesProps = {
    services: recommendedCatalogList?.map((catalog) => {
      return {
        title: catalog.title,
        description: catalog.description,
        categoryIcon: catalog.icon,
        onClick: () => {
          onClickCard(catalog.link);
        },
        onClickFavourite: () => {},
        type: catalog.isOnline ? "online" : "in-person",
        accessProfile: catalog?.accessProfile,
      };
    }),
    clickMore,
  };

  if (!recommendedCatalogList?.length) {
    return null;
  }

  return <RecommendedServices {...recommendedServicesProps} />;
};
