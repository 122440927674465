import { Fragment, useCallback, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useWebgate } from "@xvia/webgate-connect";

import { Paycheck } from "../../apis/user-related-services";

interface PaycheckCardProps {
  data: Paycheck[];
}

interface PaycheckDownloadButtonProps {
  paycheck: Paycheck;
}

function PaycheckDownloadButton({ paycheck }: PaycheckDownloadButtonProps) {
  const [isDownloading, setIsDownloading] = useState(false);

  const { accessToken, portalConnect } = useWebgate();

  const handleDownloadButtonClick = useCallback(async () => {
    setIsDownloading(true);
    await portalConnect?.download({
      url: paycheck.pdfLink,
      filename: `contacheque-${paycheck.period.month.toLowerCase()}-${
        paycheck.period.year
      }-${paycheck.bond.toLowerCase()}.pdf`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    setIsDownloading(false);
  }, [
    accessToken,
    paycheck.bond,
    paycheck.pdfLink,
    paycheck.period.month,
    paycheck.period.year,
    portalConnect,
  ]);

  return (
    <Button
      disabled={isDownloading}
      onClick={handleDownloadButtonClick}
      sx={{
        textTransform: "none",
        py: "0.25rem",
        px: "0.5rem",
        borderRadius: "0.25rem",
        backgroundColor: "#FDB913",
        color: "#231F20",
        fontWeight: 700,
        fontSize: "0.75rem",
        lineHeight: "1rem",
        ml: 2,

        "&:hover": {
          backgroundColor: "#ECA802",
        },
      }}
    >
      <>
        {isDownloading ? (
          <CircularProgress
            size={16}
            sx={{
              color: "#231F20",
              opacity: 0.5,
            }}
          />
        ) : (
          "Baixar"
        )}
      </>
    </Button>
  );
}

export default function PaycheckCard({ data }: PaycheckCardProps) {
  const isPlural = useMemo(() => data.length > 1, [data.length]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      sx={(theme) => ({
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: "0.275rem",
        border: "1px solid #E0E3EB",
        boxShadow: "1px 2px 4px 0px #ABABAB1A",
        overflow: "hidden",
      })}
    >
      <Stack
        direction="row"
        justifyContent="center"
        sx={(theme) => ({
          width: "100%",
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
          py: 1,
        })}
      >
        <Typography fontWeight={700} textAlign={"center"}>
          Obtenha{" "}
          {isPlural ? "os últimos contracheques" : "o último contracheque"}
        </Typography>
      </Stack>
      <Stack
        direction="column"
        spacing={2}
        sx={{ width: "100%", p: 2, height: "13.125rem", overflow: "auto" }}
      >
        {data.map((paycheck, index) => {
          const isLastIndex = index === data.length - 1;

          return (
            <Fragment key={paycheck.id}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography component="span">
                  {paycheck.period.month}{" "}
                  <Typography component="span" sx={{ fontWeight: 800 }}>
                    ·
                  </Typography>{" "}
                  {paycheck.period.year} ({paycheck.bond})
                </Typography>
                <PaycheckDownloadButton paycheck={paycheck} />
              </Stack>
              {!isLastIndex && (
                <Divider
                  orientation="horizontal"
                  sx={{ my: 1, width: "100%" }}
                />
              )}
            </Fragment>
          );
        })}
      </Stack>
    </Stack>
  );
}
