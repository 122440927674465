import React, { FC } from "react";
import { FooterContainer } from "../modules/footer/footer.container";
import { DepartmentListContainer } from "../modules/department-list/department-list.container";
import { HeaderGenericContainer } from "../modules/header-generic/header-generic.container";
import { SearchBarContainer } from "../components/SearchBar/SearchBar.container";
import { LoginAreaContainer } from "../modules/login-area/login-area.container";

export const DepartmentList: FC = () => {
  return (
    <div>
      <HeaderGenericContainer
        centerElement={<SearchBarContainer />}
        rightElement={<LoginAreaContainer variant="blue" />}
      />
      <div>
        <DepartmentListContainer />
      </div>
      <div>
        <FooterContainer />
      </div>
    </div>
  );
};
