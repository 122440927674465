import React, { FC, useEffect, useState } from "react";
import { useWebgate } from "@xvia/webgate-connect";

import {
  CNH,
  getPaychecks,
  Paycheck,
  UserVehicle,
} from "../../apis/user-related-services";

import {
  UserRelatedWidgets,
  UserRelatedWidgetsProps,
} from "./user-related-widgets";

export const UserRelatedWidgetsContainer: FC = () => {
  const { accessToken, userInfo } = useWebgate();

  const [paychecks, setPaychecks] = useState<Paycheck[]>([]);

  const userRelatedWidgetsProps: UserRelatedWidgetsProps = {
    paychecks,
    cnh: (userInfo?.cnh as CNH[]) ?? [],
    vehicles: (userInfo?.veiculos as UserVehicle[]) ?? [],
  };

  useEffect(() => {
    const loadPaychecks = async () => {
      const result = await getPaychecks(accessToken);
      setPaychecks(result);
    };
    if (accessToken) loadPaychecks();
  }, [accessToken]);

  if (!accessToken) return null;

  return <UserRelatedWidgets {...userRelatedWidgetsProps} />;
};
