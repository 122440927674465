import axios, { AxiosError, AxiosResponse } from "axios";

const apiUrl = "/api/metabase";
const axiosClient = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 0,
  headers: {
    "x-api-key": "mb_rsmecPl++bP2KHFFJAbFXkQZehdsN3IJ100+ha2o9WY=",
    "Content-Type": "application/json",
  },
});

const availableServicesEndpoint = "/api/dashboard/5/dashcard/70/card/61/query";
const digitalServicesEndpoint = "/api/dashboard/5/dashcard/71/card/62/query";
const citizensRegisteredEndpoint = "/api/dashboard/5/dashcard/73/card/64/query";

export type Indicators = {
  availableServices: number;
  digitalServices: number;
  citizensRegistered: number;
};

type MetabaseResponse = {
  data: {
    rows: Array<Array<number>>;
  };
};

export async function getInidicators(): Promise<Indicators> {
  const [
    availableServicesResponse,
    digitalServicesResponse,
    citizensRegisteredResponse,
  ] = await Promise.all([
    axiosClient.post<
      MetabaseResponse,
      AxiosResponse<MetabaseResponse, AxiosError>,
      {}
    >(availableServicesEndpoint, {}),
    axiosClient.post<
      MetabaseResponse,
      AxiosResponse<MetabaseResponse, AxiosError>,
      {}
    >(digitalServicesEndpoint, {}),
    axiosClient.post<
      MetabaseResponse,
      AxiosResponse<MetabaseResponse, AxiosError>,
      {}
    >(citizensRegisteredEndpoint, {}),
  ]);

  return {
    availableServices: availableServicesResponse.data.data.rows[0][0],
    digitalServices: digitalServicesResponse.data.data.rows[0][0],
    citizensRegistered: citizensRegisteredResponse.data.data.rows[0][0],
  };
}
