import React, { FC } from "react";
import { FooterContainer } from "../modules/footer/footer.container";
import { HeaderGenericContainer } from "../modules/header-generic/header-generic.container";
import { SearchBarContainer } from "../components/SearchBar/SearchBar.container";
import { DadosAbertosListContainer } from "../modules/openData-list/openData-list.container";
import { LoginAreaContainer } from "../modules/login-area/login-area.container";

export const DadosAbertosList: FC = () => {
  return (
    <div>
      <HeaderGenericContainer
        centerElement={<SearchBarContainer />}
        rightElement={<LoginAreaContainer variant="blue" />}
      />
      <div>
        <DadosAbertosListContainer />
      </div>
      <div>
        <FooterContainer />
      </div>
    </div>
  );
};
