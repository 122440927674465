import axios from "axios";
import { Catalog } from "./search-catalog";

const apiUrl = process.env.BACKEND_ENDPOINT || "";
const RECOMMENDATION_SERVICE_URL = process.env.RECOMMENDATION_SERVICE_URL || "";

const axiosClient = axios.create({
  baseURL: `${apiUrl}`,
  timeout: 0,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const recommendationClient = axios.create({
  baseURL: `${RECOMMENDATION_SERVICE_URL}`,
  timeout: 0,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  httpsAgent: new (require("https").Agent)({
    rejectUnauthorized: false,
  }),
});

interface RecommendedService {
  type: "CATALOG";
  title: string;
  slug: string;
  category: string;
  icon: string;
  accessProfile: string;
  link: string;
  department: string;
  description: string;
}

interface RecommendedServicesResponse {
  recommended_services: Catalog[];
  deprecated_services: string[];
}

const EMPTY_RESPONSE: RecommendedServicesResponse = {
  recommended_services: [],
  deprecated_services: [],
};

function transformAppLink(
  link: string,
  categorySlug: string,
  slug: string
): string {
  if (link.startsWith("http")) {
    return link;
  }
  if (link.startsWith("/app/")) {
    return `/app/catalog/${categorySlug}/${slug}`;
  }
  return `/app/catalog/${categorySlug}/${slug}`;
}

function mapRecommendedToCatalog(service: RecommendedService): Catalog {
  const categorySlug = service.category.toLowerCase().replace(/\s+/g, "-");

  return {
    type: service.type,
    title: service.title,
    slug: service.slug,
    description: service.description,
    tags: [],
    isFree: true,
    isOnline: true,
    isDigital: true,
    category: service.category,
    targets: [],
    department: service.department,
    departmentSections: [],
    categorySlug,
    highlighted: false,
    score: 0,
    imageUrl: "",
    icon: service.icon,
    link: transformAppLink(service.link, categorySlug, service.slug),
    resultTotal: 0,
    accessProfile: service.accessProfile,
    isSelfService: true,
  };
}

export async function getTarget(
  accessToken?: string,
  target?: string
): Promise<Catalog[]> {
  const response = await axiosClient.post(
    `/v1/search`,
    {
      groups: ["CATALOG"],
      targets: [target],
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}

export async function getRelevant(accessToken?: string): Promise<Catalog[]> {
  const response = await axiosClient.post(
    `/v1/search`,
    {
      groups: ["CATALOG"],
      highlighted: true,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}

export async function getTopCatalog(accessToken?: string): Promise<Catalog[]> {
  const response = await axiosClient.post(
    `/v1/search`,
    {
      top_hit: 10,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return response.data;
}

export async function getRecommendedServices(
  cpf?: string
): Promise<RecommendedServicesResponse> {
  if (!cpf) return EMPTY_RESPONSE;

  try {
    const { data } = await recommendationClient.get(
      `/recommend_services/${cpf}`,
      {
        headers: { accept: "application/json" },
      }
    );

    if (!data) return EMPTY_RESPONSE;
    return {
      recommended_services: Array.isArray(data.recommended_services)
        ? data.recommended_services.map(mapRecommendedToCatalog)
        : [],
      deprecated_services: data.deprecated_services || [],
    };
  } catch (error) {
    console.error("Error fetching recommendations:", error);
    return EMPTY_RESPONSE;
  }
}
