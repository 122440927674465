import { FC } from "react";
import { Container, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

type CardVariantColor = "green" | "yellow" | "blue";

type CardProps = {
  title: string;
  description: string;
  href: string;
  variant?: CardVariantColor;
};

const Card = ({ description, href, title, variant = "green" }: CardProps) => {
  const variantColorMap: Record<CardVariantColor, string> = {
    blue: "#034793",
    green: "#007932",
    yellow: "#DDA646",
  };

  const variantHoverColorMap: Record<CardVariantColor, string> = {
    blue: "#022B5A",
    green: "#005a23",
    yellow: "#92671B",
  };

  return (
    <Stack
      direction="column"
      gap={3}
      sx={{
        width: "100%",
        height: "auto",
        overflow: "hidden",
        borderRadius: "7px",
        backgroundColor: "white",
        boxShadow: "1px 2px 4px 0px #ABABAB1A",
        border: "1px solid #E0E3EB",
      }}
    >
      <Box
        sx={{
          padding: "12px",
          backgroundColor: variantColorMap[variant],
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            textAlign: "left",
            color: "white",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Stack
        direction="column"
        sx={{ paddingX: "12px", paddingBottom: "16px", width: "100%" }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "16px",
            textAlign: "left",
            color: "#262626BF",
          }}
        >
          {description}
        </Typography>
      </Stack>
      <Stack
        direction="column"
        sx={{
          paddingX: "12px",
          paddingBottom: "16px",
          width: "100%",
          mt: "auto",
        }}
      >
        <Typography
          component="a"
          href={href}
          sx={{
            fontSize: "12px",
            fontWeight: "600",
            lineHeight: "none",
            letterSpacing: "0.4px",
            textAlign: "center",
            color: "#E6E6E6",
            background: variantColorMap[variant],
            borderRadius: "8px",
            paddingY: "6px",
            paddingX: "16px",
            transition: "all 0.3s ease-in-out",

            "&:hover": {
              background: variantHoverColorMap[variant],
              color: "white",
              textDecoration: "none",
            },
          }}
        >
          Acessar
        </Typography>
      </Stack>
    </Stack>
  );
};

export const PublicRelatedCards: FC = () => {
  return (
    <div className="xvia-user-related-widgets">
      <Container className="xvia-user-related-widgets__container">
        <Stack
          gap={5}
          direction="row"
          sx={(theme) => ({
            width: "100%",
            mb: 2,
            mt: 5,
            [theme.breakpoints.down("sm")]: { flexDirection: "column" },
          })}
        >
          <Card
            variant="green"
            title="Cidadão"
            description="Acesse aqui serviços direcionados às necessidades corriqueiras do cidadão, como emissão de documentos, solicitação de benefícios e agendamento de atendimentos."
            href="/app/catalog/list/servicos-ao-cidadao"
          />
          <Card
            variant="yellow"
            title="Servidor"
            description="Acesse aqui as principais ferramentas e informações relacionadas a vida funcional do servidor, como contracheques e atualização de dados cadastrais."
            href="/app/catalog/list/servidor-publico"
          />
          <Card
            variant="blue"
            title="Empresas"
            description="Acesse aqui tudo o que precisa para sua empresa, como informações sobre registro, licenciamento e órgãos responsáveis."
            href="/app/catalog/list/empresas"
          />
        </Stack>
      </Container>
    </div>
  );
};
