import React, { FC, useEffect } from "react";
import { FooterContainer } from "../modules/footer/footer.container";
import { CatalogContainer } from "../modules/catalog/catalog.container";
import { SocialNetworkContainer } from "../modules/social-network/social-network.container";
import { HeaderGenericContainer } from "../modules/header-generic/header-generic.container";
import { SearchBarContainer } from "../components/SearchBar/SearchBar.container";
import { LoginAreaContainer } from "../modules/login-area/login-area.container";

export const Catalog: FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <div>
      <HeaderGenericContainer
        centerElement={<SearchBarContainer />}
        rightElement={<LoginAreaContainer variant="blue" />}
      />
      <CatalogContainer />
      <SocialNetworkContainer />
      <FooterContainer />
    </div>
  );
};
