import { CarregarMensagensData } from "./types";

import axios from "axios";

const middlewareHttpClient = axios.create({
  baseURL: "/api/govpicidadao-middleware",
});

export const carregarMensagens = async (
  accessToken: string,
  signal: AbortSignal
): Promise<CarregarMensagensData> => {
  const { data } = await middlewareHttpClient.get(
    `/notificacoes/mensagem/buscar?pageIndex=0&pageSize=999`,
    {
      signal,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  return data;
};

export const marcarComoLidas = async (
  ids: number[],
  accessToken: string
): Promise<boolean> => {
  let result = false;
  await middlewareHttpClient
    .post("/notificacoes/mensagem/marcarComoLidas", ids, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      result = true;
    })
    .catch((error) => {
      result = false;
    });

  return result;
};

export const arquivar = async (accessToken: string): Promise<boolean> => {
  let result = false;
  await middlewareHttpClient
    .post(
      "/notificacoes/mensagem/arquivar",
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      result = true;
    })
    .catch((error) => {
      result = false;
    });

  return result;
};
