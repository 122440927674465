import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import Badge from "@mui/material/Badge";
import {
  ArrowBack,
  Campaign,
  Message,
  Notifications,
  NotificationsNone,
  Star,
  Warning,
} from "@mui/icons-material";
import AcoesButton from "./AcoesButton";
import "./notificacoes.css";
import { useWebgate } from "@xvia/webgate-connect";
import useRequest from "../../hooks/useRequest";
import { arquivar, carregarMensagens, marcarComoLidas } from "./services";
import { ListaMensagens } from "./ListaMensagens";
import { Mensagem } from "./types";
import VisualizacaoMensagem from "./VisualizacaoMensagem";
import ClickAwayListener from "@mui/material/ClickAwayListener";

class ClickMensagem {
  match(msg: Mensagem): boolean {
    return false;
  }
  execute(msg: Mensagem) {}
}

class RedirectClickMensagem extends ClickMensagem {
  match(msg: Mensagem) {
    if ("onClick" in msg.metadados) {
      const dados: Object = msg.metadados["onClick"]
        ? msg.metadados["onClick"]
        : {};
      return "tipo" in dados && dados["tipo"] == "redirect";
    }

    return false;
  }
  execute(msg: Mensagem) {
    const metadados = msg.metadados;
    const dados: Object = metadados["onClick" as keyof typeof metadados];
    const url: Object = dados["url" as keyof typeof dados];
    window.open(url.toString(), "_self");
  }
}

const ACOES_CLICK = [new RedirectClickMensagem()];

const ICONES: { [key: string]: ReactElement } = {
  alerta: <Warning />,
  informacao: <Campaign />,
  novidade: <Star />,
};

const ICONE_DEFAULT = <Message />;

export default function Notificacoes({ isBlue = false }: { isBlue: boolean }) {
  const { accessToken, userInfo } = useWebgate();
  const [containerExpandido, setContainerExpandido] = useState<boolean>(false);
  const [mensagens, setMensagens] = useState<Mensagem[]>([]);
  const [mensagem, setMensagem] = useState<Mensagem | undefined>(undefined);
  const [countNaoLidas, setCountNaoLidas] = useState<number>(0);

  const carregarMensagensCallback = useCallback(
    (signal: AbortSignal) => {
      if (accessToken) {
        return carregarMensagens(accessToken, signal);
      } else {
        return Promise.resolve(undefined);
      }
    },
    [accessToken]
  );

  const {
    data: carregarMensagensData,
    error: carregarMensagensError,
    loading: carregarMensagensLoading,
  } = useRequest(carregarMensagensCallback, undefined);

  const refreshCountNaoLidas = () => {
    setCountNaoLidas(mensagens.filter((msg) => !msg.visualizada).length);
  };

  useEffect(() => {
    if (carregarMensagensData && carregarMensagensData.mensagens) {
      setMensagens(carregarMensagensData?.mensagens || []);
    }
  }, [carregarMensagensData]);

  useEffect(() => {
    refreshCountNaoLidas();
  }, [mensagens]);

  const handleExpandirContainer = () => {
    setContainerExpandido(!containerExpandido);
  };

  const handleSelecionarMensagem = (msg: Mensagem) => {
    if (!msg.visualizada) {
      msg.visualizada = true;
      if (accessToken) {
        marcarComoLidas([msg.id], accessToken);
        refreshCountNaoLidas();
      }
    }

    let deu_match = false;
    ACOES_CLICK.forEach((acao) => {
      if (acao.match(msg)) {
        deu_match = true;
        acao.execute(msg);
      }
    });

    if (!deu_match) {
      setMensagem(msg);
    }
  };

  const handleExibirListagem = () => {
    setMensagem(undefined);
  };

  const handleMarcarComoLidas = () => {
    if (accessToken) {
      const mensagensNaoLidas = mensagens
        ? mensagens.filter((msg) => !msg.visualizada)
        : [];
      const ids = mensagensNaoLidas.map((msg) => msg.id);

      if (ids.length > 0) {
        marcarComoLidas(ids, accessToken);
        mensagensNaoLidas.forEach((msg) => (msg.visualizada = true));
        refreshCountNaoLidas();
      }
    }
  };

  const handleArquivar = () => {
    if (accessToken) {
      arquivar(accessToken);
      setMensagens([]);
    }
  };

  let contentAcoes = <React.Fragment />;
  let content = <React.Fragment />;
  let btnVoltarVisibility = "hidden";

  if (mensagens.length > 0) {
    mensagens.forEach((msg) => {
      msg.icone = msg.tipo in ICONES ? ICONES[msg.tipo] : ICONE_DEFAULT;
    });

    contentAcoes = (
      <AcoesButton
        handleMarcarComoLidas={handleMarcarComoLidas}
        handleArquivar={handleArquivar}
      />
    );
  } else {
    contentAcoes = <span className="span-acoes" />;
  }

  if (mensagem) {
    btnVoltarVisibility = "visible";
    content = <VisualizacaoMensagem mensagem={mensagem} />;
  } else {
    btnVoltarVisibility = "hidden";
    content = (
      <ListaMensagens
        mensagens={mensagens}
        handleClick={handleSelecionarMensagem}
      />
    );
  }

  const iconeNormal = (
    <NotificationsNone
      className={isBlue ? "icone-blue" : "icone"}
      color="action"
    />
  );
  const iconeExpandido = (
    <Notifications className={isBlue ? "icone-blue" : "icone"} color="action" />
  );

  return (
    <React.Fragment>
      <ClickAwayListener
        onClickAway={() => {
          setContainerExpandido(false);
        }}
      >
        <Box className={"box-notificacoes"}>
          <IconButton onClick={handleExpandirContainer}>
            <Badge badgeContent={countNaoLidas} color="warning">
              {containerExpandido ? iconeExpandido : iconeNormal}
            </Badge>
          </IconButton>
          <Box
            className={"container"}
            sx={{ display: containerExpandido ? "block" : "none" }}
          >
            <div className={"ajuste-borda"}></div>
            <Box className={"header"}>
              <IconButton
                aria-label="Voltar"
                size="small"
                onClick={handleExibirListagem}
                sx={{ visibility: btnVoltarVisibility }}
              >
                <ArrowBack />
              </IconButton>
              <span>Notificações</span>
              {contentAcoes}
            </Box>
            {content}
          </Box>
        </Box>
      </ClickAwayListener>
    </React.Fragment>
  );
}
