import {
  createElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { LoginArea, LoginAreaProps, LoginAreaVariant } from "./login-area";
import { useWebgate } from "@xvia/webgate-connect";
import { useMediaQuery } from "@mui/material";

type LoginAreaContainerProps = {
  variant?: LoginAreaVariant;
};

export const LoginAreaContainer = ({
  variant = "white",
}: LoginAreaContainerProps) => {
  const { portalConnect, userInfo, accessToken } = useWebgate();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const isMobile = useMediaQuery("(max-width: 991px)");

  const onClickDocuments = useCallback(() => {
    document.location.href = "/app/app-documentos";
  }, []);

  const onClickSolicitations = useCallback(() => {
    document.location.href = "/app/acompanhar-minhas-solicitacoes";
  }, []);

  const onClickLogin = useCallback(() => {
    portalConnect?.login({
      redirectUri: `${process.env.PORTAL_URL}`,
    });
  }, [portalConnect]);

  const onClickLogout = useCallback(() => {
    portalConnect?.logout({});
  }, [portalConnect]);

  const onClickAccount = useCallback(() => {
    portalConnect?.accountManagement();
  }, [portalConnect]);

  const userName = useMemo(() => {
    if (!userInfo?.name || !accessToken) {
      return;
    }
    const parts = (userInfo.name as string).split(" ");
    if (parts.length > 1) {
      return `${parts[0]} ${parts[parts.length - 1]}`;
    }

    return userInfo.name;
  }, [userInfo?.name, accessToken]);

  const userLevelRole = useMemo(() => {
    if (!userInfo?.name || !accessToken) {
      return;
    }

    if (userInfo?.roles.some((role: string) => role === "opala"))
      return "opala";
    if (userInfo?.roles.some((role: string) => role === "ouro")) return "ouro";
    if (userInfo?.roles.some((role: string) => role === "prata"))
      return "prata";
    if (userInfo?.roles.some((role: string) => role === "bronze"))
      return "bronze";
  }, [userInfo, accessToken]);

  useEffect(() => {
    if (!!accessToken) {
      setLoggedIn(true);
      return;
    }
    setLoggedIn(false);
  }, [accessToken, userInfo]);

  const props: LoginAreaProps = {
    isLoggedIn,
    userName,
    onClickLogin,
    onClickAccount,
    onClickLogout,
    userLevelRole,
    onClickDocuments,
    onClickSolicitations,
    variant,
    isMobile,
  };

  return createElement(LoginArea, props);
};
