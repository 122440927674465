import React, { useCallback, useEffect, useState } from "react";
import { Box, Stack, Typography, Divider } from "@mui/material";
import {
  CancelRounded,
  CheckCircleOutlineRounded,
  Info,
} from "@mui/icons-material";

import "./cnh-card.css";

import { CNH } from "../../apis/user-related-services";

type CnhCardProps = {
  data: CNH;
  onClick?: () => void;
};

type Orientation = "vertical" | "horizontal";

function CnhCard({ data, onClick }: CnhCardProps) {
  const [orientation, setOrientation] = useState<Orientation>("vertical");

  useEffect(() => {
    function handleResize() {
      setOrientation(window.innerWidth > 550 ? "vertical" : "horizontal");
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const DOIS_MESES_EM_MS = 5_184_000_000;
  const cnhProximaVencimento =
    Date.parse(data?.dataValidadeCNH) - Date.now() < DOIS_MESES_EM_MS;

  const isCnhExpired = (validadeDate: string): boolean => {
    const [day, month, year] = validadeDate
      .split("/")
      .map((part) => parseInt(part, 10));
    const cnhDate = new Date(year, month - 1, day);
    cnhDate.setHours(0, 0, 0, 0);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return cnhDate < today;
  };

  const cnhIsExpired = isCnhExpired(data.dataValidadeCNH || "");
  const cnhStatusText = cnhIsExpired
    ? "Sua CNH está vencida"
    : "Sua CNH está regular";
  const alertBackgroundColor = cnhIsExpired ? "#EF4123" : "#007932";
  const typhographyBackgroundColor = cnhIsExpired ? "#000" : "#007932";

  const loadCnh = useCallback(() => {
    window.open("/app/app-minha-cnh", "_self");
  }, []);

  const statusCnh = () => {
    if (data?.cnhVencida) return "Vencida";
    if (data?.bloqueioBca || data?.bloqueioCnhApreendida)
      return "Possui restrição";
    if (cnhProximaVencimento) return "Próxima do vencimento";
    return "Regular";
  };

  const iconeDeStatus = () => {
    if (
      [data?.bloqueioBca, data?.bloqueioCnhApreendida, data?.cnhVencida].some(
        Boolean
      )
    ) {
      return <CancelRounded color="error" />;
    }
    if (cnhProximaVencimento) return <Info color="warning" />;
    return <CheckCircleOutlineRounded color="success" />;
  };

  const formataData = (data: string | undefined) => {
    if (!data) return "-";
    const [ano, mes, dia] = data.split("-");
    return `${dia}/${mes}/${ano}`;
  };

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="flex-start"
      sx={(theme) => ({
        width: "100%",
        backgroundColor: "#fff",
        borderRadius: "0.275rem",
        cursor: "pointer",
        overflow: "hidden",
        boxShadow: "1px 2px 4px 0px #ABABAB1A",
        border: "1px solid #E0E3EB",
      })}
      onClick={loadCnh}
    >
      <Stack
        direction="row"
        justifyContent="center"
        sx={(theme) => ({
          width: "100%",
          backgroundColor: alertBackgroundColor,
          color: "#fff",
          py: 1,
        })}
      >
        <Typography fontWeight={700}>{cnhStatusText}</Typography>
      </Stack>
      <Stack direction="column" sx={{ width: "100%", p: 2 }}>
        <Stack direction="row" justifyContent="space-between">
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: { width: "50%" },
            })}
          >
            <Typography sx={{ color: "#8c8c8c" }}>Situação</Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                color: alertBackgroundColor,
                "& svg": {
                  width: "1rem",
                  marginRight: "0.25rem",
                },
              }}
            >
              {iconeDeStatus()}
              <Typography sx={{ fontWeight: 500 }} component="span">
                {statusCnh()}
              </Typography>
            </Typography>
          </Box>
          <Box
            textAlign="right"
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: { width: "50%" },
            })}
          >
            <Typography sx={{ color: "#8c8c8c" }}>Categoria</Typography>
            <Typography
              sx={{ color: typhographyBackgroundColor, fontWeight: 500 }}
            >
              {data?.categoriaCNH}
            </Typography>
          </Box>
        </Stack>
        <Divider
          orientation={orientation}
          sx={{ my: 1, width: "100%", paddingX: "2rem" }}
        ></Divider>
        <Stack direction="row" justifyContent="space-between">
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: { width: "50%" },
            })}
          >
            <Typography sx={{ color: "#8c8c8c" }}>N&deg; da CNH</Typography>
            <Typography
              sx={{ color: typhographyBackgroundColor, fontWeight: 500 }}
            >
              {data?.numeroRegistro}
            </Typography>
          </Box>
          <Box
            textAlign="right"
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: { width: "50%" },
            })}
          >
            <Typography sx={{ color: "#8c8c8c" }}>Registro</Typography>
            <Typography
              sx={{ color: typhographyBackgroundColor, fontWeight: 500 }}
            >
              {data?.registro}
            </Typography>
          </Box>
        </Stack>
        <Divider sx={{ my: 1, width: "100%", paddingX: "2rem" }}></Divider>
        <Stack direction="row" justifyContent="space-between">
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: { width: "50%" },
            })}
          >
            <Typography sx={{ color: "#8c8c8c" }}>Emissão</Typography>
            <Typography
              sx={{ color: typhographyBackgroundColor, fontWeight: 500 }}
            >
              {formataData(data?.dataEmissao?.toString())}
            </Typography>
          </Box>
          <Box
            textAlign="right"
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: { width: "50%" },
            })}
          >
            <Typography sx={{ color: "#8c8c8c" }}>Validade</Typography>
            <Typography
              sx={{ color: typhographyBackgroundColor, fontWeight: 500 }}
            >
              {data?.dataValidadeCNH}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default CnhCard;
