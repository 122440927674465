import React, { FC, useEffect, useState } from "react";
import { getInidicators, Indicators } from "../../apis/indicators";
import { IndicatorsSection } from "./indicators-section";

import { IndicatorsSectionProps } from "./indicators-section";

export const IndicatorsSectionContainer: FC = () => {
  const [indicators, setIndicators] = useState<Indicators | null>(null);

  useEffect(() => {
    const loadIndicators = async () => {
      const result = await getInidicators();
      setIndicators(result);
    };

    loadIndicators();
  }, []);

  if (!indicators) return null;

  const indicatorsSectionProps: IndicatorsSectionProps = {
    data: {
      availableServices: indicators.availableServices,
      digitalServices: indicators.digitalServices,
      citizensRegistered: indicators.citizensRegistered,
    },
  };

  return <IndicatorsSection {...indicatorsSectionProps} />;
};
