import * as React from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { MoreHoriz } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Mensagem } from "./types";

type AcoesButtonProps = {
  handleMarcarComoLidas: Function;
  handleArquivar: Function;
};

export default function AcoesButton({
  handleMarcarComoLidas,
  handleArquivar,
}: AcoesButtonProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup ref={anchorRef}>
        <IconButton onClick={handleToggle} aria-label="Ações" size="small">
          <MoreHoriz />
        </IconButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper className="acoes-panel">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  <MenuItem
                    key={"marcar-todas-lidas"}
                    onClick={(event) => {
                      handleMarcarComoLidas();
                      handleMenuItemClick(event);
                    }}
                  >
                    Marcar todas como lidas
                  </MenuItem>
                  <MenuItem
                    key={"limpar-mensagens"}
                    onClick={(event) => {
                      handleArquivar();
                      handleMenuItemClick(event);
                    }}
                  >
                    Limpar mensagens
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
