import React, { FC, useCallback, useEffect, useState } from "react";
import { RelevantServices, RelevantServicesProps } from "./relevant-services";
import { useWebgate } from "@xvia/webgate-connect";
import { Catalog } from "../../apis/search-catalog";
import { getServerData } from "../../util/get-server-data";
import {
  getRecommendedServices,
  getRelevant,
} from "../../apis/relevant-catalog";
import { useHistory } from "react-router-dom";

export const RelevantServicesContainer: FC = () => {
  const { accessToken, serverData, userInfo } = useWebgate();
  let history = useHistory();
  const cpf = userInfo?.preferred_username;

  const [relevantCatalogList, setRelevantCatalogList] = useState<Catalog[]>(
    serverData?.relevant || getServerData("relevant", null)
  );

  const [isLoading, setIsLoading] = useState(false);

  const getServerRelevant = useCallback(() => {
    setIsLoading(true);
    if (!userInfo || Object.keys(userInfo).length === 0) {
      getRelevant(accessToken)
        .then((regularServices) => {
          setRelevantCatalogList(regularServices);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
        });
      return;
    } else {
      getRecommendedServices(cpf)
        .then((recommendedResult) => {
          if (recommendedResult.recommended_services?.length > 0) {
            setRelevantCatalogList(recommendedResult.recommended_services);
            setIsLoading(false);
          } else {
            return getRelevant(accessToken);
          }
        })
        .then((regularServices) => {
          if (regularServices) {
            setRelevantCatalogList(regularServices);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          getRelevant(accessToken)
            .then((regularServices) => {
              setRelevantCatalogList(regularServices);
            })
            .catch((fallbackError) => {
              console.error("Error fetching fallback services:", fallbackError);
            })
            .finally(() => {
              setIsLoading(false);
            });
        });
    }
  }, [cpf, accessToken, userInfo]);

  useEffect(() => {
    getServerRelevant();
  }, [getServerRelevant]);

  const onClickCard = useCallback(
    (catalog: Catalog) => {
      if (catalog.link.startsWith("http")) {
        window.location.href = catalog.link;
      } else if (catalog.link.startsWith("/app/")) {
        window.location.href = catalog.link;
      } else {
        history.push(`${catalog.categorySlug}/${catalog.slug}`);
      }
    },
    [history]
  );

  const relevantServicesProps: RelevantServicesProps = {
    services: relevantCatalogList?.map((catalog) => ({
      title: catalog.title,
      description: catalog.description,
      categoryIcon: catalog.icon,
      onClick: () => onClickCard(catalog),
      imageUrl: catalog.imageUrl,
      onClickFavourite: () => {},
      type: catalog.isOnline ? "online" : "in-person",
      targets: catalog.targets,
      accessProfile: catalog.accessProfile,
    })),
  };

  return <RelevantServices {...relevantServicesProps} />;
};
