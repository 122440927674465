import React, { FC, useEffect } from "react";
import { FooterContainer } from "../modules/footer/footer.container";
import { ListContainer } from "../modules/list/list.container";
import { HeaderGenericContainer } from "../modules/header-generic/header-generic.container";
import { SearchBarContainer } from "../components/SearchBar/SearchBar.container";
import { LoginAreaContainer } from "../modules/login-area/login-area.container";

export const List: FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <div>
      <HeaderGenericContainer
        centerElement={<SearchBarContainer />}
        rightElement={<LoginAreaContainer variant="blue" />}
      />
      <ListContainer />
      <FooterContainer />
    </div>
  );
};
