import { FC, useEffect, useState, useCallback } from "react";
import { AppLoader, AppLoaderProps } from "../components/AppLoader/AppLoader";
import { useLocation } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/ssr";
import { getSlugFromPath } from "../util/get-slug-from-path";
import { usePortalProvider } from "../providers/portal-provider";
import {
  NotFoundError,
  RequestStatus,
  UnauthorizedError,
} from "../util/Service";
import { SearchBarContainer } from "../components/SearchBar/SearchBar.container";
import { IAppLoader } from "../apis/IAppLoader";
import { HeaderContainer } from "../components/Header/Header.container";

import { Backdrop, Container, GlobalStyles } from "@mui/material";
import { OpenSideBarButton } from "../components/OpenSideBarButton/OpenSideBarButton";
import { LoginAreaContainer } from "../components/login-area/login-area.container";

interface AppLoaderContainerProps extends IAppLoader {
  slug?: string;
}

export const AppLoaderContainer: FC<AppLoaderContainerProps> = ({ slug }) => {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>();
  const { initialized, keycloak } = useKeycloak();
  const { backend, setCurrentApp, currentApp, ssr } = usePortalProvider();
  const [sideBarIsExpandend, setSideBarIsExpanded] = useState<boolean>(false);
  const appSlug = slug || getSlugFromPath(pathname, "");
  const isCatalog = appSlug === "catalog";

  const loadApp = useCallback(
    async (pathname: string, appBasePath: string): Promise<void> => {
      const appSlug = slug || getSlugFromPath(pathname, appBasePath);

      if (currentApp && currentApp.slug === appSlug) {
        return;
      }

      try {
        backend?.setAccessToken(keycloak?.token);
        const app = await backend?.request(`/v1/app/${appSlug}`);
        console.log(app);
        if (!keycloak?.token && !app?.public) {
          return;
        }
        setLoading(true);
        setCurrentApp(undefined);
        setRequestStatus(undefined);
        setCurrentApp(app);
      } catch (error: unknown) {
        if (error instanceof UnauthorizedError) {
          setRequestStatus(RequestStatus.UNAUTHORIZED);
        } else if (error instanceof NotFoundError) {
          setRequestStatus(RequestStatus.NOT_FOUND);
        } else {
          setRequestStatus(RequestStatus.INTERNAL_ERROR);
        }
      } finally {
        setLoading(false);
      }

      return;
    },
    [backend, setCurrentApp, setLoading, currentApp, keycloak?.token, slug]
  );

  useEffect(() => {
    if (!initialized && !currentApp) {
      setLoading(true);
      return;
    }
    if (!initialized) {
      return;
    }

    loadApp(pathname, APP_BASE_PATH);
  }, [pathname, keycloak?.authenticated, initialized, loadApp, currentApp]);

  const handlerOpenCloseSideBar = () => {
    if (sideBarIsExpandend) {
      setSideBarIsExpanded(false);
    } else {
      setSideBarIsExpanded(true);
    }
  };

  const props: AppLoaderProps = {
    app: currentApp,
    loading,
    authenticated: !!keycloak?.authenticated,
    ssr,
    requestStatus,
  };

  return (
    <>
      <GlobalStyles
        styles={{ html: { overflowY: sideBarIsExpandend ? "hidden" : "auto" } }}
      />

      <div className="xvia-main-app">
        {!!currentApp && !currentApp?.noHeader ? (
          <HeaderContainer
            centerElement={<SearchBarContainer />}
            rightElement={<LoginAreaContainer variant="blue" />}
            openSideBarIcon={
              <OpenSideBarButton
                handlerOpenCloseSideBar={handlerOpenCloseSideBar}
                sideBarIsExpandend={sideBarIsExpandend}
              />
            }
          />
        ) : null}

        <div className="xvia-main-app__wrapp-content-area">
          <Backdrop
            open={sideBarIsExpandend}
            onClick={handlerOpenCloseSideBar}
            sx={(theme) => ({
              [theme.breakpoints.down(992)]: {
                display: "block",
                width: "100%",
                height: "100vh",
                zIndex: "10000 !important",
                opacity: "0.45 !important",
              },
              display: "none",
            })}
          />

          <div
            className="xvia-content-area"
            style={{
              maxWidth: isCatalog ? "none" : "1200px",
              margin: "0 auto",
              padding: isCatalog ? "16px 285px" : "0 20px",
            }}
          >
            <div
              id="root"
              dangerouslySetInnerHTML={{
                __html: currentApp?.parsedContent?.renderedContent || "",
              }}
            />
            <AppLoader {...props} />
          </div>
        </div>
      </div>
    </>
  );
};
