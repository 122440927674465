import {
  Box,
  Button,
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Mensagem } from "./types";

import "./notificacoes.css";

type ListaMensagensProps = {
  mensagens: Mensagem[];
  handleClick: Function;
};

export const ListaMensagens: React.FC<ListaMensagensProps> = ({
  mensagens,
  handleClick,
}) => {
  const mensagensTodas = mensagens ? mensagens : [];
  const mensagensNaoLidas = mensagensTodas.filter((msg) => !msg.visualizada);

  const [mensagensListagem, setMensagensListagem] =
    useState<Mensagem[]>(mensagensTodas);

  const [showTodas, setShowTodas] = useState<boolean>(true);

  useEffect(() => {
    if (mensagens) {
      setMensagensListagem(mensagensTodas);
    }
  }, [mensagens]);

  const handleExibirTodas = () => {
    setMensagensListagem(mensagensTodas);
    setShowTodas(true);
  };

  const handleExibirNaoLidas = () => {
    setMensagensListagem(mensagensNaoLidas);
    setShowTodas(false);
  };

  let conteudo = [<React.Fragment />];

  if (mensagensListagem.length > 0) {
    conteudo = mensagensListagem.map((msg: Mensagem) => (
      <ListItem
        key={msg.id}
        className={msg.visualizada ? "list-item msg-lida" : "list-item"}
        onClick={() => handleClick(msg)}
      >
        <ListItemIcon className={"icone-msg"}>{msg.icone}</ListItemIcon>
        <ListItemText
          primary={
            <Box className={"titulo-msg"}>
              <div>{msg.titulo}</div>
              <span>&#x2022;</span>
            </Box>
          }
          secondary={
            <React.Fragment>
              <span className={"texto-msg"}>{msg.texto}</span>
              <span className={"data-msg"}>{msg.dataCriacaoFormatada}</span>
            </React.Fragment>
          }
        />
      </ListItem>
    ));
  } else {
    conteudo = [
      <ListItem className="list-item msg-lida" key={0}>
        <ListItemText
          primary={
            <Box className={"titulo-msg"}>
              <div>Não há mensagens para serem exibidas.</div>
            </Box>
          }
        />
      </ListItem>,
    ];
  }

  return (
    <Box>
      <Box className="barra-btn">
        <Button
          variant={showTodas ? "contained" : "text"}
          sx={{
            color: showTodas ? "#fff" : "#3D445C",
            backgroundColor: "#034EA2 !important",
          }}
          size="small"
          onClick={handleExibirTodas}
        >
          Todas
        </Button>
        <Button
          variant={showTodas ? "text" : "contained"}
          sx={{
            color: showTodas ? "#3D445C" : "#fff",
            backgroundColor: "#034EA2 !important",
          }}
          size="small"
          onClick={handleExibirNaoLidas}
        >
          Não Lidas
        </Button>
      </Box>
      <List className={"list"}>{conteudo}</List>
    </Box>
  );
};
